import Swal from 'sweetalert2';

export const openCrmWindow = (contactId) => {
  const crmUrl = process.env.REACT_APP_DYNAMICRM + contactId;
  window.open(crmUrl, '_blank');
};

const toggleSiderDisplay = (isMobile, displayStyle) => {
  if (isMobile) {
      const sidebarElement = document.querySelector('.ant-layout-sider');
      if (sidebarElement) {
          sidebarElement.style.display = displayStyle;
      }
  }
};

export const hideSider = (isMobile) => toggleSiderDisplay(isMobile, 'none');
export const showSider = (isMobile) => toggleSiderDisplay(isMobile, 'block');

export const formatPhoneNumber = (phoneNumber) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const numberWithoutCountryCode = digitsOnly.startsWith('1') ? digitsOnly.slice(1) : digitsOnly;
  const match = numberWithoutCountryCode.match(/(\d{3})(\d{3})(\d{4})/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
};

export const formatDate = (startTime) => {
  const localDate = new Date(startTime).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  return localDate.replace(/\//g, '-');
};

export const formatTime = (startTime) => { 
    const dateObjectUTC = new Date(Date.parse(startTime));
    const offsetInMilliseconds = new Date().getTimezoneOffset() * 60 * 1000;
    const dateObjectTimeZone = new Date(dateObjectUTC.getTime() - offsetInMilliseconds);
    const timeZone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    try { 
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timeZone,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
  
      const formattedTime = formatter.format(dateObjectTimeZone);
      return formattedTime;
    } catch (error) {
      console.error("Error formatting time:", error);
      return null;
    }
  };

export const getActiveCalls = async () => {
  try {
      const response = await fetch(process.env.REACT_APP_GETACTIVECALLS, {
          headers: {
              "APIKEY": process.env.REACT_APP_CALLAPIKEY,
              "Origin": '*',
              "crossorigin": "use-credentials",
              "X-Requested-With": "XMLHttpRequest"
          },
          method: 'GET'
      });

      if (response.ok) {
          const data = await response.json();
          return data.data;
      } else {
          throw new Error(response.statusText);
      }
  } catch (error) {
      console.error("Error fetching active calls:", error);
      return null;
  }
};

export const getUserCommunities = async () => {
  const storedListString = sessionStorage.getItem('UserCommunities');
  if (!storedListString) {
      console.error("Failed to fetch user communities");
      return null;
  }

  try {
      return JSON.parse(storedListString);
  } catch (error) {
      console.error("Error parsing stored data:", error);
      return null;
  }
};

export function showFailedToSendTranscript() {
  Swal.close();
  Swal.fire({
      title: "Failed to send transcript to C3. Please try again.",
      showClass: {
          popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
      }
  });
}