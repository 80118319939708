import React from 'react';
import { Layout, List, Typography, ListSubheader } from "antd";
import conversationsListStyles from "./assets/ConversationsList.module.css";
import conversationsItemStyles from "./assets/ConversationsItem.module.css";
import { joinClassNames } from "./utils/class-name";
import ConversationsMessages from './ConversationsMessages';
import Swal from 'sweetalert2';
import "animate.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import AccordionMenu from './Accordion';
import 'font-awesome/css/font-awesome.min.css';
import { Sort } from '@material-ui/icons';
import "./assets/listItem.css";
import Call from './Call';
import { formatDate, formatTime, formatPhoneNumber } from './ConversationsUtils';
import * as signalR from '@microsoft/signalr';
import { getActiveCalls, getUserCommunities } from './ConversationsUtils';

const { Text } = Typography;

export class ConversationsList extends React.Component {

  constructor(props) {   
    super(props);
    this.state = {
      startOutboundConversation: false,
      toPhone: "", //Twilio phone
      fromPhone: "",  //customer phone
      customerName: "",
      messageBody: "A new conversation started",
      companyProjectNum: "",
      communityName: "",
      newMessageConversationSid: this.props.newMessageConversationSid,
      newMessageConvoSid: this.props.newMessageConvoSid,
      newListItemOpened: this.props.newListItemOpened,
      sidTobeRemoved: this.props.sidTobeRemoved,
      communityList: this.props.communityList,
      contentVisible: true,
      isMobile: this.props.isMobile,
      navwidth: this.props.navWidth,

      showCallComponent: false,
      displayCallContent: false,
      userCommunityList: "",
      sortedData: [],
      clickedRowData: null,
      jsonCallData: [{
        "id": "59a58983-53d0-4ada-8f87-4df14aa009ac",
        "firstName": "Vanessa",
        "lastName": "Molle",
        "sid": null,
        "contactId": null,
        "fromPhone": "9492147245",
        "toPhone": "3215870402",
        "companyCode": "3011",
        "projectCode": "000",
        "startTime": "2023-12-22T19:20:05.44+00:00"
    },
    {
        "id": "4c77be44-8701-4144-a27d-74c10221436b",
        "firstName": "Lawrence",
        "lastName": "  Gelfond",
        "sid": null,
        "contactId": "e166e451-e022-ec11-b6e6-000d3a3653ae",
        "fromPhone": "3109959003",
        "toPhone": "3215870402",
        "companyCode": "3011",
        "projectCode": "000",
        "startTime": "2024-02-02T21:41:53.323+00:00"
    },
    {
        "id": "d1bb0b52-f65c-46ba-b65d-c3f3502d2178",
        "firstName": "Tai",
        "lastName": "Pham",
        "sid": "Orlando Area",
        "contactId": "a8cc62ad-14c3-ee11-9079-0022480adf5a",
        "fromPhone": "9515350410",
        "toPhone": "3215870402",
        "companyCode": "3011",
        "projectCode": "000",
        "startTime": "2024-02-05T21:30:44.314"
    }],
      endcall: true,    
      signalRconnected : false,  
      isCallTrackingEnabled: this.props.isCallTrackingEnabled
    }

  }

  handleRowClick(index) {
    const displayCallContent = true;
    const clickedSortedData = this.state.sortedData[index];

    this.props.onCallClick(
      displayCallContent,
      clickedSortedData.id,
      clickedSortedData.firstName,
      clickedSortedData.lastName,
      formatPhoneNumber(clickedSortedData.fromPhone),
      clickedSortedData.contactId,
      clickedSortedData.startTime 
    );
  }
  componentDidMount = async () => {   
   
    await this.fetchCallLog();
    const checkSessionInterval = setInterval(async () => {
        if (sessionStorage.getItem("UserCommunities")) {
            clearInterval(checkSessionInterval);
            await this.tryFetchCallLog();
        }
    }, 2000);

    this.startSignalRConnection();
}

tryFetchCallLog = async () => {
    try {
        await this.fetchCallLog();
    } catch (error) {
        console.error("Error fetching call log:", error);
    }
}
  startSignalRConnection = () => {
    if (this.state.signalRConnected) {
        console.log("SignalR already connected.");
        return;
    }
    const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_SIGNALCONNECTION)
        .build();

    newConnection.start()
        .then(() => {
            console.log("SignalR connected");
            this.setState({ signalRConnected: true, connection: newConnection });
        })
        .catch((error) => console.error('SignalR Connection Error: ', error));

    newConnection.on('ReceiveMessage', (message) => {
        console.log("Message from server: ", message);
        this.fetchCallLog(); 
    });
}

  sortData = async () => { 
    var defaultUserCommunityList = ["2085652512"];
    var userCommList = this.state.userCommunityList !== null ? this.state.userCommunityList : defaultUserCommunityList;
    const filteredData = this.state.jsonCallData.filter(item => userCommList.includes(item.toPhone));
    const sortedData = filteredData.slice().sort((a, b) => {
      return new Date(b.startTime) - new Date(a.startTime);
    });
    this.setState({ sortedData });
  }

  fetchCallLog = async () => {
    const activeCalls = await getActiveCalls();
   if (activeCalls) {
      this.setState({jsonCallData: activeCalls}); 
   }
   const userCommunities = await getUserCommunities();
   if (userCommunities) {
     this.setState({userCommunityList: userCommunities}, () =>{
       this.sortData();
     })
   }  
  }

  renderTableRows() {
    return this.state.sortedData.map((data, index) => {
      
      const formattedDate = formatDate(data.startTime);  
      const formattedPhoneNumber = formatPhoneNumber(data.fromPhone);
      
      let formattedTime = '';
      if (data.startTime) {
        formattedTime = formatTime(data.startTime); 
      }

      const handleMouseEnter = (e) => {
        e.currentTarget.style.transform = 'scale(1.05)';
        e.currentTarget.style.backgroundColor = '#4b4b4b';
        e.currentTarget.style.width = '100%';
        e.currentTarget.style.height = '55px';
        e.currentTarget.style.cursor = 'pointer';
      };
      
      const handleMouseLeave = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.backgroundColor = 'transparent';
      };
      return (
      <tr key={index} 
      className="convolist-active-calls-row"
      style={{color: 'white', 
              fontSize: '16px', 
              fontWeight: '600',           
              transition: 'transform 0.3s, background-color 0.3s'
             }} 
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={ () => this.handleRowClick(index)}
              >
        <td><span class="convolist-items-bullets" style={{padding: '12px'}}>
            <span class="dot-gray"></span>
              <span>{data.firstName} {data.lastName} {data.sid}</span>         
              <br />
              <span style={{ marginLeft: '13px' }}>{formattedPhoneNumber}</span>
              <span className="convolist-active-calls-datetime" style={{
                fontSize: '12px', 
                fontStyle: 'italic',
                 marginLeft: '10px',
                 position: 'relative',
                 marginBottom: '10px'
                 }}> {formattedDate} {formattedTime}</span>
            </span>
        </td>
      </tr>
        );
      });
    }

  render() {
    const { conversations, selectedConversationSid, onConversationClick, identity, friendlyName, 
      isUpdated,openNav, name, onCallClick, newMessageConversationSid, newMessageConvoSid, sidTobeRemoved, newListItemOpened, 
      isMobile  } = this.props;
    const { isCallTrackingEnabled } = this.props;
    return (

      <Layout className="convolist-layout">
        <div>
          {isMobile == true ? (
            <div className="convolist-ismobile">
              <div className="accordion_container">
                <div className="accordion">
                <div className="accordion_title" >
                    All Active Text
                  </div>
                </div>

                <div className={`accordion_content ${this.state.contentVisible ? "show_content" : ""}`}>
                  <List className="list" bordered={false} loading={conversations.length === 0}
                    dataSource={conversations.sort((a, b) => a.dateCreated < b.dateCreated ? 1 : -1)}
                    renderItem={item => {
                      const activeChannel = item.sid === selectedConversationSid;
                      const conversationItemClassName = joinClassNames([conversationsItemStyles['conversation-item'],
                      activeChannel && conversationsItemStyles['conversation-item--active']
                      ]);
                      return (
                        <div>
                          {newMessageConvoSid.length > 0 ? (
                            <List.Item className='convolist-list-item1'
                              key={item}
                              onClick={  ((openNav),  () => onConversationClick(item))  }>
                              <Text style={{ color: 'white' }} strong className="row" >
                              <span class='convolist-items-bullets'>
                                  {((newMessageConvoSid).indexOf(item.sid) > -1) ? <span class={'dot-green'}></span>
                                    : <span class={'dot-gray'}></span>
                                  }
                                  <span style={{ placeItems: 'left' }}>{item.friendlyName.split('_')[0] + " " + item.friendlyName.split('_')[3]}</span>
                                </span>
                              </Text>
                            </List.Item>
                          )
                            : (
                              <List.Item className='convolist-list-item1'
                                key={item}
                                onClick={  ((openNav),  () => onConversationClick(item))  }>
                                <Text style={{ color: 'white' }} strong className="row" >
                                <span class='convolist-items-bullets'>
                                    {((newMessageConvoSid).indexOf(item.sid) > -1) ? <span class={'dot-green'}></span>
                                      :
                                      <span class={'dot-gray'}></span>
                                    }
                                    {item.friendlyName.split('_')[0] + " " + item.friendlyName.split('_')[3]}
                                  </span>
                                </Text>
                              </List.Item>
                            )}
                        </div>
                      )
                    }}
                  />
                </div>
                <div>
                        {isCallTrackingEnabled && (
                                
                                <>
                          <div className="accordion" style={{ marginTop: '50%' }}>
                                  <button
                                    type="button"
                                    className="accordion_title"
                                    onClick={this.fetchCallLog}
                                  >
                                    All Active Calls
                                  </button>
                          </div>
                          <div className={`accordion_content ${this.state.contentVisible ? "show_content" : ""}`}>
                              <div style={{ maxHeight: '288px', overflowY: 'auto', overflowX: 'hidden' }}>
                                <table style={{ backgroundColor: '#b4ada5', width: '100%' }}>
                                  <tbody>{this.renderTableRows()}</tbody>
                                </table>
                              </div>
                            </div>
                            </>
                        )}
                </div>
              </div>           
            </div>) :
                  (
                    <div className="convolist-containter" style={{ marginLeft: "-8px", width: this.props.navWidth, position: "relative", zIndex: 8 }} >
                      <div className="accordion_container">
                        <div className="accordion">
                          <div className="accordion_title">
                            All Active Text
                          </div>
                        </div>
                        <div className={`accordion_content ${this.state.contentVisible ? "show_content" : ""}`}
                          style={{ maxHeight: '390px', minHeight: '300px', overflowY: 'auto', overflowX: 'hidden'  }}>
                    <List style={{ paddingTop: '0px', color: 'white', placeContent: 'center' }}
                      className="list"
                      bordered={false}
                      loading={conversations.length === 0}
                      dataSource={conversations.sort((a, b) => a.dateCreated < b.dateCreated ? 1 : -1)}
                      renderItem={item => {
                        const activeChannel = item.sid === selectedConversationSid;
                        const conversationItemClassName = joinClassNames([conversationsItemStyles['conversation-item'],
                        activeChannel && conversationsItemStyles['conversation-item--active']
                        ]);
                        return (
                          <div>
                            {newMessageConvoSid.length > 0 ? (
                              <List.Item style={{ placeItems: 'center', backgroundColor: '#b4ada5' }}
                                key={item}
                                onClick={() => {onConversationClick(item)}}>
                                <Text style={{ color: 'white' }} strong className="row" >
                                  <span class='convolist-items-bullets'>
                                    {((newMessageConvoSid).indexOf(item.sid) > -1) ? <span class={'dot-green'}></span>
                                      : <span class={'dot-gray'}></span>
                                    }
                                    <span>{item.friendlyName.split('_')[0] + " " + item.friendlyName.split('_')[3]}</span>
                                  </span>
                                </Text>
                              </List.Item>
                            )
                              : (
                                <List.Item style={{ placeItems: 'center', backgroundColor: '#b4ada5' }}
                                  key={item}
                                 onClick={() => { onConversationClick(item) }   }>
                                  <Text style={{ color: 'white' }} strong className="row" >
                                    <span class='convolist-items-bullets'>
                                      {((newMessageConvoSid).indexOf(item.sid) > -1) ? <span class={'dot-green'}></span>
                                        :
                                        <span class={'dot-gray'}></span>
                                      }
                                      <span>{item.friendlyName.split('_')[0] + " " + item.friendlyName.split('_')[3]}</span>
                                    </span>
                                  </Text>
                                </List.Item>
                              )}
                          </div>
                        )
                      }}
                    />
                   </div>   
                   <div>
                     { isCallTrackingEnabled && (                                
                        <>
                            <div className="accordion" style={{marginTop: '15%'}}>
                            <button
                                type="button"
                                className="accordion_title"
                                onClick={this.fetchCallLog}              
                              >
                                All Active Calls
                              </button>
                            </div>
                            <div className={`accordion_content ${this.state.contentVisible ? "show_content" : ""}`}>  
                              <div style={{ maxHeight: '288px', overflowY: 'auto', overflowX: 'hidden'  }}>
                                <table style={{backgroundColor: '#b4ada5', width: '100%'}}>
                                  <tbody>{this.renderTableRows()}</tbody>
                                </table>
                              </div>
                            </div>
                       </>
                        )}
                </div>
                </div>
              </div>
            )

          }
        </div>


      </Layout>


    )
  }
}
